<template>
  <div>
    <!-- 出行人信息 -->
    <en-table-layout :tableData="travelersTableData.data" :loading="loading" :tips="true">
      <!-- 导出 -->
      <div slot="tips" style="display:flex;justify-content:flex-end;width:100%;padding:16px 35px;">
        <div class="conditions">
          <el-button @click="downLoad" type="primary" class="btn-default" size="mini">导出</el-button>
        </div>
      </div>
      <!-- 表格上部的信息 -->
      <div slot="toolbar">
        <h4>出行人信息</h4>
      </div>
      <template slot="table-columns">
        <!-- 姓名 -->
        <el-table-column prop="chinese_name" label="出行人姓名" />
        <!-- 英文姓 -->
        <!-- <el-table-column prop="eng_first_name" label="英文姓" /> -->
        <!-- 英文名 -->
        <!-- <el-table-column prop="eng_second_name" label="英文名" /> -->
        <!-- 证件类型 -->
        <!-- <el-table-column label="证件类型">
          <template slot-scope="scope">
            <div>{{scope.row.doc_type===1?"身份证":scope.row.doc_type===2?"护照":scope.row.doc_type===3?"港澳台通行证":"其他"}}</div>
          </template>
</el-table-column> -->
        <!-- 证件号 -->
        <!-- <el-table-column prop="doc_nums" label="证件号" /> -->
        <!-- 证件有效期截止日 -->
        <!-- <el-table-column label="证件有效期截止日">
          <template slot-scope="scope">
            <div>{{scope.row.doc_validity?scope.row.doc_validity:"终身有效"}}</div>
          </template>
        </el-table-column> -->
        <!-- 国籍 -->
        <!-- <el-table-column label="国籍">
          <template slot-scope="scope">
            <div>{{scope.row.nationality===1?"中国":"其他"}}</div>
          </template>
        </el-table-column> -->
        <!-- 手机号 -->
        <el-table-column prop="phone" label="出行人手机号" />
        <!-- 出行人类型 -->
        <!-- <el-table-column label="出行人类型">
          <template slot-scope="scope">
            <div>{{scope.row.identity_type===1?"成人":"儿童"}}</div>
          </template>
        </el-table-column> -->
        <!-- 是否为本人 -->
        <!-- <el-table-column label="是否为本人">
          <template slot-scope="scope">
            <div>{{scope.row.self===1?"是":"否"}}</div>
          </template>
        </el-table-column> -->
        <!-- 儿童年龄 -->
        <!-- <el-table-column label="儿童年龄">
          <template slot-scope="scope">
            {{ scope.row.identity_type === 1 ? '' : scope.row.child_age}}
          </template>
        </el-table-column> -->
      </template>
    </en-table-layout>

    <!-- 紧急联系人信息 -->
    <en-table-layout :tableData="urgentTableData.data" :loading="loading" :tips="true" class>
      <!-- 表格上部的信息 -->
      <div slot="toolbar">
        <h4>紧急联系人信息</h4>
      </div>
      <template slot="table-columns">
        <!-- 姓名 -->
        <el-table-column prop="name" label="姓名" />
        <!-- 手机号 -->
        <el-table-column prop="phone" label="手机号" />
      </template>
    </en-table-layout>
  </div>
</template>

<script>
import * as API_orderList from "@/api/orderList";
import * as Export2Excel from "@/utils/exportXlsx";

export default {
  name: "activityList",
  data () {
    return {
      //  列表loading状态
      loading: false,

      //  出行人数据
      travelersTableData: {
        data: []
      },

      //  紧急联系人信息
      urgentTableData: {
        data: []
      },

      // 订单id
      order_id: ""
    };
  },
  mounted () {
    this.order_id = this.$route.params.id;
    this.GET_tradeOrdersTAndE();
  },
  activated () {
    this.order_id = this.$route.params.order_id;
    this.GET_tradeOrdersTAndE();
  },
  methods: {
    /** 获取订单列表 */
    GET_tradeOrdersTAndE () {
      API_orderList.tradeOrdersTAndE(this.order_id).then(res => {
        this.travelersTableData.data = res.travelers;
        this.urgentTableData.data = res.emergenciers;
        // console.log('API_orderList.tradeOrdersTAndE', res);
      });
    },

    /** 导出 */
    downLoad () {
      API_orderList.tradeOrdersTAndE(this.order_id).then(res => {
        var emergenciersExcelData = [];
        res.emergenciers.forEach(item => {
          var emergenciersRow = [];
          emergenciersRow.push(item.name);
          emergenciersRow.push(item.phone);
          emergenciersRow.push(res.trade_sn);
          emergenciersExcelData.push(emergenciersRow);
        });

        var travelersExcelData = [];
        res.travelers.forEach(item => {
          var travelersRow = [];
          travelersRow.push(item.chinese_name);
          travelersRow.push(item.eng_first_name);
          travelersRow.push(item.eng_second_name);
          travelersRow.push(
            item.doc_type === 1
              ? "身份证"
              : item.doc_type === 2
                ? "护照"
                : item.doc_type === 3
                  ? "港澳台通行证"
                  : "其他"
          );
          travelersRow.push(item.doc_nums);
          travelersRow.push(item.doc_validity ? item.doc_validity : "终身有效");
          travelersRow.push(item.nationality === 1 ? "中国" : "其他");
          travelersRow.push(item.phone);
          travelersRow.push(item.identity_type === 1 ? "成人" : "儿童");
          travelersRow.push(item.self === 1 ? "是" : "否");
          travelersRow.push(item.child_age);
          travelersRow.push(res.trade_sn);
          travelersExcelData.push(travelersRow);
        });

        Export2Excel.export_json_to_excel(
          [
            "姓名",
            "英文姓",
            "英文名",
            "证件类型",
            "证件号",
            "证件有效期截止日",
            "国籍",
            "手机号",
            "出行人类型",
            "是否为本人",
            "儿童年龄",
            "订单编号"
          ],
          travelersExcelData,
          "出行人列表数据"
        );
        Export2Excel.export_json_to_excel(
          ["姓名", "手机号", "订单编号"],
          emergenciersExcelData,
          "紧急联系人列表数据"
        );
      });
    }
  }
};
</script>

<style type="text/scss" lang="scss" scoped>
/deep/ .el-table td:not(.is-left) {
  text-align: center;
}

/deep/ .toolbar {
  padding: 0 36px !important;

  h4 {
    color: #333;
  }
}

/deep/ .tips-info {
  padding: 0 !important;
}
</style>
